import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-493f5ebb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "CxList"
}
const _hoisted_2 = { style: {"font-size":"14px","font-weight":"bold","color":"#2c3e50"} }
const _hoisted_3 = {
  key: 0,
  style: {"color":"red"}
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { style: {"font-size":"14px","font-weight":"bold","color":"#2c3e50"} }
const _hoisted_6 = { style: {"font-size":"14px","font-weight":"bold","color":"#2c3e50"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      "row-class-name": _ctx.outDaysClass
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("出运日期：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.cyrq,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageList.queryParam.cyrq) = $event)),
                  type: "daterange",
                  shortcuts: _ctx.utils.Tools.defDateRange(),
                  style: {"width":"96%"}
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("收汇到期日：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.shdqr,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.shdqr) = $event)),
                  type: "daterange",
                  shortcuts: _ctx.utils.Tools.defDateRange(),
                  style: {"width":"95%"}
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("订单编码：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[2] || (_cache[2] = e => _ctx.pageList.queryParam.code = _ctx.valid(e)),
                  placeholder: "请输入订单编码",
                  modelValue: _ctx.pageList.queryParam.code,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.code) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (!_ctx.engineParams)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 3,
                  class: "myColTitle"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("客户名称：")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (!_ctx.engineParams)
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 1,
                  span: 9
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      onInput: _cache[4] || (_cache[4] = e => _ctx.pageList.queryParam.customerName = _ctx.valid(e)),
                      placeholder: "请输入客户名称",
                      modelValue: _ctx.pageList.queryParam.customerName,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageList.queryParam.customerName) = $event)),
                      clearable: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_ORDER_CODE",
          label: "订单编码"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tag, {
              onClick: ($event: any) => (_ctx.piDetail(scope.row.F_PI_ID)),
              style: {"cursor":"pointer"},
              title: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.F_ORDER_CODE), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CUSTOMER_NAME",
          label: "客户名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_JS_TYPE",
          label: "结算币种"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.row.F_JS_TYPE=='0'?'人民币':'美元'), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_OA_AMOUNT",
          label: "OA支付金额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_OA_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CYRQ",
          label: "出运日期"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SHDQR",
          label: "收汇到期日"
        }, {
          default: _withCtx((scope) => [
            (scope.row.F_LESS_DAYS<0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.F_SHDQR), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(scope.row.F_SHDQR), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_LESS_DAYS",
          label: "收汇到期天数"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(scope.row.F_LESS_DAYS), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "pfbl",
          label: "赔付比例"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_6, _toDisplayString(scope.row.F_CONTENT?JSON.parse(scope.row.F_CONTENT).pfbl:'') + "%", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_REMARK",
          label: "备注"
        })
      ]),
      _: 1
    }, 8, ["pageList", "row-class-name"])
  ]))
}