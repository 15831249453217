import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance, nextTick} from 'vue';
import KxqrListUtil ,{IKxqrListDataObj} from './kxqrListUtil';

export default defineComponent ({
    name: 'KxqrList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IKxqrListDataObj=reactive<IKxqrListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'款项确认',
                queryParam: {},
                cardFrom:'KxqrList',
                border:false,
                isShowFixCol:false,
                modelMethod: utils.OrderProviderApi.buildUrl('/kxqr/pageData')
            },
            otherParams:{
                count:0,
                dialogVisible:false,
                selInfo:{
                    F_PI_ID:'',//piId
                    _F_SRC_TYPE:'',//确认类别
                    F_ORDER_CODE:'',//订单号
                    F_AMOUNT:0,//金额
                    F_JS_TYPE:'RMB',//结算币种
                },
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new KxqrListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        return{
            ...toRefs(dataObj)
        }
    }
});